import { useMutation } from "@tanstack/react-query";
import { useApiHandler } from "./useApiHandler";
import { IUserLogin } from "../models/UserLogin";
import IDefaultResponse from "../models/DefaultResponse";
import { IUserCreateUpdate } from "../models/UserCreateUpdate";

export default function useUser() {
    const { get, post } = useApiHandler();

    const login = useMutation({
        mutationFn: (creds: IUserLogin) => post<IDefaultResponse<any>>("/user/login", creds)
    });

    const register = useMutation({
        mutationFn: (creds: IUserCreateUpdate) => post<IDefaultResponse<any>>("/user/register", creds)
    });

    return {
        login,
        register
    }
}