import { createContext, useContext, useEffect, useState } from "react";
import NotFoundPage from "../../screens/NotFound";
import HomePage from "../../screens/Home";
import AboutPage from "../../screens/About";
import Page from "./Page";
import LoginPage from "../../screens/Login";
import RegisterPage from "../../screens/Register";
import ComingSoonPage from "../../screens/ComingSoon";

export type IRouteOptions = '' | 'home' | 'about' | 'login' | 'register' | undefined;

export interface IRouterProps{
    defaultRoute?: IRouteOptions;
    disableRouteChange?: boolean;
}

export const RouteContext = createContext<{
    route: IRouteOptions;
    changeRoute: (r: IRouteOptions)=>void;
  } | undefined>(undefined);
  
export default function Router(props: IRouterProps){
    const [ notFound, setNotFound ] = useState(false);
    const [ route, setRoute ] = useState<IRouteOptions>(props.defaultRoute);

    useEffect(() => {
        if (props.disableRouteChange) return;
        
        const hash = window.location.hash.replace('#', '');
        if (hash) {
            setRoute(hash as IRouteOptions);
        }
    }, []);

    useEffect(()=>{
        setNotFound(route == undefined);
    }, [route])

    const changeRoute = (r: IRouteOptions) => {
        if (props.disableRouteChange) return;

        setRoute(r);
        document.location.hash = r?.toString()!;
    }

    const getPage = () => {
        switch (route){
            case 'home':
                return <HomePage/>

            case 'about':
                return <AboutPage/>
        }
    }

    const specialPage = () => {
        switch (route){
            case '':
                return <ComingSoonPage/>

            case 'login':
                return <LoginPage/>

            case 'register':
                return <RegisterPage/>

            default:
                return null;
        }
    }

    return <RouteContext.Provider value={{route, changeRoute}}>
        { notFound ? <NotFoundPage/> :
            specialPage() ?? <Page>{getPage()}</Page>}
    </RouteContext.Provider>
}

export function useRoute() {
    const context = useContext(RouteContext);
    if (context === undefined) {
      throw new Error('useRoute must be used within a RouteProvider');
    }
    return context;
}