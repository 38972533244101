import { AtSymbolIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import StyledImage from "../Shared/StyledImage";
import StyledLink from "../Shared/StyledLink";

export default function Footer(){
    return <div className="py-4 px-8 grid gap-y-4 gap-x-[7rem] grid-cols-1 md:grid-cols-[auto,1fr,1fr,1fr] grid-flow-row w-[100vw] bg-wi-sec text-wi-font-pri">
        <div className="justify-self-start font-semibold">
            <StyledImage srcHref="/media/media/hampshire-wi-logo-stacked.png" classes="h-[8rem]"/>
            <div className="pl-1">
                <p className="mt-4">© 2024 Somerset WI Federation.</p>
                <p className="mt-2">Website By Smeaton Software.</p>
            </div>
        </div>
        <div className="gap-2 mt-1 flex flex-col text-nowrap text-wi-font-pri font-semibold">
            <h2 className="text-wi-font-tri font-semibold text-2xl">Contact Us</h2>
            <a className="flex"><PhoneIcon height={"1.2rem"} className="text-wi-font-tri mr-2 min-w-[1.2rem]"/>01278 496400</a>
            <a className="flex"><AtSymbolIcon width={"1.2rem"} className="text-wi-font-tri mr-2 min-w-[1.2rem]"/>fedsec@somerset-wi.org.uk</a>
            <a className="flex text-wrap"><EnvelopeIcon width={"1.2rem"} className="text-wi-font-tri mr-2 min-w-[1.2rem]"/>Somerset County Federation of Women's Institutes, 344 Bristol Road, Bridgwater, Somerset, TA6 4AQ</a>
        </div>
        <div className="gap-2 mt-1 flex flex-col text-nowrap text-wi-font-pri font-semibold">
            <h2 className="text-wi-font-tri font-semibold text-2xl">More From Us</h2>
            <StyledLink onClick={()=>window.open("https://somerset.thewi.org.uk/", "_blank")} text="Somerset Federation"/>
            <StyledLink onClick={()=>window.open("https://www.facebook.com/somersetwi/", "_blank")} text="Our Facebook"/>
        </div>
        <div className="gap-2 mt-1 flex flex-col text-nowrap text-wi-font-pri font-semibold">
            <h2 className="text-wi-font-tri font-semibold text-2xl">More Of The WI</h2>
            <StyledLink onClick={()=>window.open("https://thewi.org.uk/", "_blank")} text="The WI"/>
            <StyledLink onClick={()=>window.open("https://www.instagram.com/womensinstitute/", "_blank")} text="Instagram"/>
            <StyledLink onClick={()=>window.open("https://www.twitter.com/womensinstitute/", "_blank")} text="Twitter"/>
            <StyledLink onClick={()=>window.open("https://www.facebook.com/thewi/", "_blank")} text="Facebook"/>
        </div>
    </div>
}