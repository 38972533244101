import { IRouteOptions, useRoute } from "./Router";
import StyledImage from "../Shared/StyledImage";
import StyledLink from "../Shared/StyledLink";
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid'
import StyledButton from "../Shared/StyledButton";

interface IRouteLinkMap{
    label: string;
    route: IRouteOptions;
}

export default function TopBar(){
    const { route, changeRoute } = useRoute();

    const linkMap: IRouteLinkMap[] = [
        {
            label: "Home",
            route: 'home'
        },
        {
            label: "About",
            route: 'about'
        }
    ]

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 bg-wi-pri py-2 px-4 items-center">
            <div className="justify-self-start mx-auto md:mx-0">
                <StyledImage srcHref="/media/media/wi-logo-long-white.png" classes="h-[4rem]"/>
            </div>
            <div className="flex justify-center flex-grow flex-wrap gap-x-6 gap-y-2 my-2">
                {
                    linkMap.map(link => <StyledLink key={link.route} text={link.label} onClick={()=>changeRoute(link.route)} active={link.route == route}/>)
                }
            </div>
            <div className="justify-self-center md:justify-self-end gap-2 mt-1 flex flex-row text-nowrap text-wi-font-pri">
                <StyledButton label="Join Us" colour="bg-wi-sec" onClick={()=>changeRoute('home')}/>
                <StyledButton label="Login" colour="bg-wi-tri" classes="text-wi-font-sec" onClick={()=>changeRoute('login')}/>
            </div>
        </div>
    )
}