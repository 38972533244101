import exp from 'constants';
import { useState } from 'react';

export const baseUrl = 'http://localhost:5000/api';


export function useApiHandler(){
    const _getReqOpts = async () => {
        const reqOpts = {
            headers: {
                "Content-Type": "application/json",
                // ...(await _getBearer()),
            },
            // ...config.options,
        };

        return reqOpts;
    };

    async function _handleResponse<T>(res: Response): Promise<T> {
        return await res.json() as T;
    }

    async function _doRequest<T>(path: string, method: string, body?: any) {
        let rawRes = await fetch(`${baseUrl}${path}`, {
            ...(await _getReqOpts()),
            body: body ? JSON.stringify(body) : undefined,
            method: method,
        });

        return await _handleResponse<T>(rawRes);
    }

    async function get<T>(path: string) {
        return _doRequest<T>(path, "GET");
    }

    async function post<T>(path: string, body: any) {
        return _doRequest<T>(path, "POST", body);
    }

    async function put<T>(path: string, body: any) {
        return _doRequest<T>(path, "PUT", body);
    }

    async function del<T>(path: string) {
        return _doRequest<T>(path, "DELETE");
    }

    return {
        get,
        post,
        put,
        del
    }
}