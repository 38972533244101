import { FacebookEmbed } from "react-social-media-embed";
import StyledImage from "../components/Shared/StyledImage";

export default function HomePage(){
    return <div className="flex-grow ">
        <h1 className="text-3xl font-bold underline">
        Hello world!
        </h1>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <FacebookEmbed url="https://www.facebook.com/somersetwi/posts/pfbid0iN9aURfmVsuRDAbMT6gestjMkBb616SmCzezEwDW2ngzgjy6C8d3ahTY6Fi1DKaxl" width={550} />
        </div>
    </div>
}