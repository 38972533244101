import { useState } from "react";
import StyledButton from "../components/Shared/StyledButton";
import StyledImage from "../components/Shared/StyledImage";
import { useRoute } from "../components/Page/Router";
import { IUserCreateUpdate } from "../models/UserCreateUpdate";
import useUser from "../providers/useUser";

export default function RegisterPage(){
    const [ creds, setCreds ] = useState<IUserCreateUpdate>({
        email: '',
        password: '',
        fullName: '',
        phone: '',
        contactEmail: ''
    });
    const [ error, setError ] = useState<string>('');
    const { route, changeRoute } = useRoute();
    const { register } = useUser();

    const doRegister = () => {
        register.mutate(creds,{
            onSuccess: (data) => {
                if (data.state==0){
                    changeRoute('login');
                }
                else{
                    setError(data.message || '');
                }
            }
        });
    };

    return <div className="min-h-screen flex-grow flex items-center justify-center bg-wi-sec">
    <div className="border-2 border-wi-pri rounded-md min-w-[35vw]">
        <div className="bg-wi-pri py-4">
            <StyledImage srcHref="/media/media/wi-logo-long-white.png" classes="h-[4rem] flex justify-center"/>
        </div>
        <div className="bg-wi-tri  rounded-b-md flex flex-col p-4 gap-4 text-wi-font-sec">
            <div>
                <h2 className="text-center font-bold text-xl">Create An Account With Us</h2>
                <p className="text-center">You'll be able to find out more and join us as a member.</p>
            </div>
            <div className="flex flex-col">
                <label className="font-semibold" htmlFor="email">Email:</label>
                <input className="rounded-md border-2 border-wi-pri p-2" type="text" id="email" name="email" onChange={x=>setCreds({...creds, email: x.currentTarget.value})}/>
            </div>
            <div className="flex flex-col">
                <label className="font-semibold" htmlFor="password">Password:</label>
                <input className="rounded-md border-2 border-wi-pri p-2" type="password" id="password" name="password" onChange={x=>setCreds({...creds, password: x.currentTarget.value})}/>
            </div>
            <div className="flex flex-col">
                <label className="font-semibold" htmlFor="fullName">Full Name:</label>
                <input className="rounded-md border-2 border-wi-pri p-2" type="text" id="fullName" name="fullName" onChange={x=>setCreds({...creds, fullName: x.currentTarget.value})}/>
            </div>
            <div className="flex flex-col">
                <label className="font-semibold" htmlFor="phone">Phone:</label>
                <input className="rounded-md border-2 border-wi-pri p-2" type="text" id="phone" name="phone" onChange={x=>setCreds({...creds, phone: x.currentTarget.value})}/>
            </div>
            {error && <div className="text-red-500 text-center font-semibold">{error}, try again.</div> }
            <div className="flex flex-row gap-x-2 min-w-full">
                <StyledButton label="Register" colour="bg-wi-pri" classes="!m-0 py-2 w-1/2" onClick={doRegister}/>
                <StyledButton label="Goto Login" colour="bg-wi-sec" classes="!m-0 py-2 w-1/2" onClick={()=>changeRoute('login')}/>
            </div>
            <p className="text-center">
                You shall receive a confirmation email to verify your email address.<br/>
                Your data will never be shared with 3rd parties.
            </p>
        </div>
    </div>
</div>
}