import { useRoute } from "../components/Page/Router";
import StyledButton from "../components/Shared/StyledButton";
import StyledImage from "../components/Shared/StyledImage";
import StyledLink from "../components/Shared/StyledLink";

export default function ComingSoonPage(){
    const { route, changeRoute } = useRoute();
    
    return <div className="min-h-screen flex items-center justify-center bg-wi-tri py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col justify-center">
            <StyledImage srcHref="/media/media/wi-logo.png" classes="mx-auto"/>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                We are working on something!
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
                Why not check out our other pages?<br/><br/>
                <a href="https://www.thewi.org.uk/" target="_blank" className={`text-wi-font-sec font-semibold text-lg cursor-pointer border-b-2 w-fit border-wi-pri hover:border-wi-sec`}>The National WI Federation</a>
            </p>
        </div>
    </div>
</div>
}