import Footer from "./Footer";
import TopBar from "./TopBar";

export interface IPageProps{
    children: any;
}

export default function Page(props: IPageProps){
    return <div className="flex flex-col min-h-screen max-w-screen overflow-x-hidden">
        <TopBar/>
        {props.children}
        <Footer/>
    </div>
}