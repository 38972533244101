import React from 'react';
import logo from './logo.svg';
import StyledImage from './components/Shared/StyledImage';
import Router from './components/Page/Router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const QC = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={QC}>
      <div className='bg-wi-tri min-h-[100vh] font-pragmatica'>
        <Router defaultRoute='' disableRouteChange={true}/>
      </div>
    </QueryClientProvider>
  );
}

export default App;
