export interface IStyledButtonProps{
    label: string;
    onClick: () => void;
    colour?: string | undefined;
    classes?: string | undefined;
    customClasses?: string | undefined;
}

export default function StyledButton(props: IStyledButtonProps){
    return <button type="button" className={props.customClasses ? props.customClasses : `rounded-md py-1 px-2 m-auto text-wi-font-pri font-semibold ${props.colour ? props.colour : "bg-wi-pri"} ${props.classes}`} onClick={props.onClick}>
        {props.label}
    </button>
}